.modalWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.backDrop {
    background-color: rgba(0,0,0,.8);
    width: 100%;
    height: 100%;
}

.modalBody {
    max-height: 90vh;
    overflow-y: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    min-width: 300px;
    max-width: 800px;
    min-height: 150px;
    background: white;
    border-radius: 20px;
    /* padding: 1rem; */
    display: flex;
    flex-direction: column;
}

.closeButton {
    position: absolute;
    top: 15px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
    font-weight: bold;
}

.closeText {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1rem;
    cursor: pointer;
    color: #888;
    font-weight: bold;
}

.closeText:hover {
    color: #555;
}

.modalTitle {
    text-align: center;
    padding: .5rem 0;
}

.modalContent {
    padding: 1rem 0;
}

.modalFooter {
    margin-top: auto;
    padding: .5rem 0;
    display: flex;
    justify-content: flex-end;
}