@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

.variant-scroll::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
.variant-scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
.variant-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background-color: lightgray;
  /*outline: 1px solid slategrey;*/
}

.table-scroll::-webkit-scrollbar {
  height: 10px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}

.table-scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}

.table-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background-color: lightgray;
  /*outline: 1px solid slategrey;*/
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 1500!important;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.notes .MuiInputBase-input {
  padding: 6px 15px 7px !important;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  .print-label-container {
    page-break-after: always;
  }
}

@page {
  size: auto;
  margin: 22mm;
}

.swal-modal .swal-text {
  text-align: center;
}

/* REACT-PAGINATE STYLINGS */
/* .item must be first */
.pagination-wrapper {
  margin-top: .5rem;
  font-size: .85rem;
}
.pagination-wrapper .pagination > li {
display: inline-flex;
padding-left: 0;
list-style: none;
/* border: 0.9px solid; */
height: 100%;
}

.pagination-wrapper .pagination > li > a,
.pagination-wrapper .pagination > li > span {
position: relative;
float: left;
padding: 3px 12px;
text-decoration: none;
color: #2c689c;
background-color: #fff;
border: 1px solid #cedaec;
margin-left: -1px;
}
.pagination-wrapper .pagination>li.active>a {
color: #fff;
background-color: #6386b9;
border-color: #6386b9;
}

.pagination-wrapper .pagination > li > a:hover {
background-color:  #bdd7ea;
color: white;
}

.pagination-wrapper .previous a {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.pagination-wrapper .next a {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

/* TOGGLE SWITCH STYLES */
/* The switch - the box around the slider */
.oci-switch.container {
  width: 42px;
  height: 26px;
  position: relative;
}

/* Hide default HTML checkbox */
.oci-switch .checkbox {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.oci-switch .switch {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #e9e9eb;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

/* The slider */
.oci-switch .slider {
  width: 20px;
  height: 20px;
  position: absolute;
  left: calc(50% - 20px/2 - 10px);
  top: calc(50% - 20px/2);
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.oci-switch .checkbox:checked + .switch {
  background-color: #34C759;
}

.oci-switch .checkbox:checked + .switch .slider {
  left: calc(50% - 20px/2 + 10px);
  top: calc(50% - 20px/2);
}
/* ///////// */

/* React to Print styles */
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  .separator {
    display: none;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 20mm;
}